import { Oauth2Scheme}  from '~auth/runtime'

export default class KeycloakScheme extends Oauth2Scheme {


  constructor($auth, options, ...defaults) {
    options.redirect = {
      login: $auth.ctx.localePath('/'),
      logout: $auth.ctx.localePath('/?logout'),
      callback: $auth.ctx.localePath('login'),
      home: $auth.ctx.localePath('/feature/write-chapter'),
    };
    $auth.options.redirect = options.redirect
    super($auth, options, ...defaults);
  }
}
