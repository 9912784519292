export function getTemplates() {
  return this.get(`/api/prompt_templates`)
}
export function sendPrompt(templateSlug, data) {
  return this.post(`/api/prompt_templates/${templateSlug}/execute`, data)
}

export function sendPromptBatch(templateSlug, data) {
  return this.post(`/api/prompt_templates/${templateSlug}/execute_batch`, data)
}
export function getPrompts(slug) {
  return this.get(`/api/prompts?prompt_template=${slug}`)
}