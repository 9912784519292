export function getArticles() {
  return this.get(`/api/articles`)
}

export function fetchArticles(data) {
  return this.post(`/api/articles/fetch`, data)
}

export function getArticle(id) {
  return this.get(`/api/articles/${id}`)
}

export function getArticleMessages(id) {
  return this.get(`/api/articles/${id}/messages`)
}

export function postArticle(data) {
  return this.post(`/api/articles`, data)
}

export function deleteArticle(id) {
  return this.del(`/api/articles/${id}`)
}
