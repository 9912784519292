import posthog from 'posthog-js'
import Vue from 'vue'

export default function({ app, $config, $auth }, inject) {
  if (!$config.posthogToken) return

  if (!app.mixins) app.mixins = []
  app.mixins.push({
    watch: {
      '$consent.cookies.analytics': {
        handler(newVal) {
          if (newVal) {
            init();
          }
        },
        immediate: true
      }
    },
  })


  if ($auth) {
    app.mixins.push({
      data() {
        return {
          currentPosthogUser: null,
        }
      },
      methods: {
        identifyPosthogUser(user){
          if (!this.$posthog) return
          this.currentPosthogUser = user
          this.$posthog.identify(user.email)
        }
      },
      mounted() {
        this.$nextTick(() => {
          if (this.$auth?.user) {
            this.identifyPosthogUser(this.$auth.user)
          } else {
            this.$store.watch((state)=> {
                if (state.auth.user && !this.currentPosthogUser) {
                  this.identifyPosthogUser(state.auth.user)
                }
              },
              (_)=>{
              },
              {
                deep:true
              }
            );
          }
        })
      },
    })
  }
  function init() {
    // Init PostHog
    posthog.init($config.posthogToken, {
      api_host: 'https://eu.i.posthog.com',
      person_profiles: 'identified_only',
      capture_pageview: false,
    })

    // Make sure that pageviews are captured with each route change
    app.router.afterEach(to => {
      Vue.nextTick(() => {
        /* Note: this might also be a good place to call posthog.register(...) in order to update your properties
        on each page view
        */
        posthog.capture('$pageview', {
          $current_url: to.fullPath
        })
      })
    })

    // Inject PostHog into the application and make it available via this.$posthog (or app.$posthog)
    inject('posthog', posthog)
  }
}
