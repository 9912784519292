export default function ({$axios, app}) {
  $axios.onError((error) => {
    error.details = error.response?.data
  })

  $axios.interceptors.request.use((config) => {
    if (app?.i18n?.locale) {
      $axios.setHeader('Accept-Language', app?.i18n?.locale)
    }
    return config;
  });
}
