import Vue from 'vue'
import Survey from '@/components/user/Survey'

const STORAGE_FLAG_NAME = 'survey'
const DELAY_TIMER = 120 // seconds

let mountedComponent = null
let Component = null
let timerTimeout = null


export default ({ app }, inject) => {
  if (!process.client) return
  if (!app.$auth.loggedIn) return
  if (localStorage.getItem(STORAGE_FLAG_NAME)) return

  const show = () => {
    if (mountedComponent) return
    if (!app.$auth.loggedIn) return
    if (localStorage.getItem(STORAGE_FLAG_NAME)) return
    const ele = document.getElementById('app')
    const node = document.createElement('div')
    node.setAttribute('id', 'surveyCard')
    if (ele) {
      ele.appendChild(node)
      mountedComponent = Component.$mount('#surveyCard')
      console.info("Survey shown")
    }
  }

  const remove = () => {
    if (!mountedComponent) return
    mountedComponent.$destroy()
    mountedComponent.$el.parentNode.removeChild(mountedComponent.$el)
    mountedComponent = null
    console.info("Survey removed")
  }

  /**
   * 
   * @param {Number} time is seconds 
   */
  const startTimer = (time = DELAY_TIMER) => {
    if (timerTimeout) return
    timerTimeout = setTimeout(() => {
      show()
    }, time * 1000)
    console.info(`Survey timer started (${time}s)`)
  }
  const cancelTimer = () => {
    if (!timerTimeout) return
    clearTimeout(timerTimeout)
    console.info("Survey timer canceled")
  }

  const confirm = () => {
    localStorage.setItem(STORAGE_FLAG_NAME, true)
  }

  app.mixins.push({
    mounted() {
      const SurveyCard = Vue.component('SurveyCard', Survey)
      Component = new SurveyCard({ parent: this })
    },
  })
  inject('survey', {
    startTimer,
    cancelTimer,
    show,
    remove,
    confirm
  })
}
