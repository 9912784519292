export default function (context) {
  context.app.mixins = context.app.mixins || []
  context.app.mixins.push({
    watch: {
      '$vuetify.theme.dark'() {
        this.$cookies.set('isDark', this.$vuetify.theme.isDark)
      },
    },
    created() {
      this.$vuetify.theme.isDark = this.$cookies.get('isDark')
    },
  })
}