
export default {
  name: 'ErrorLayout',
  components: {
    ContactForm: () => import('@/components/user/ContactForm'),
  },
  layout: 'fullscreen',
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  head() {
    return {
      title: this.is404
        ? '404 - Seite nicht gefunden'
        : 'Ein Fehler ist aufgetreten',
    }
  },
  computed: {
    is404() {
      return this.error.statusCode === 404
    },
    email() {
      return this.$auth.user?.email || 'nouser'
    },
  },
}
