
import { mdiChevronUp, mdiStarOutline, mdiStar, mdiChevronLeft } from '@mdi/js'
import { sendContact } from '@/api/contact.service'
export default {
  name: 'SurveyCard',
  data: () => ({
    mdiChevronUp,
    mdiStarOutline,
    mdiStar,
    mdiChevronLeft,
    rating: null,
    isExtended: false,
    comment: '',
    step: 0,
    isClosed: false,
  }),
  mounted() {
    setTimeout(() => {
      this.isExtended = true
    }, 500)
  },
  methods: {
    onNext() {
      if (this.step === 1) {
        this.onComplete()
      }
      this.step++
    },
    onClose() {
      this.isClosed = true
      this.$survey.confirm()
      setTimeout(() => {
        this.$survey.remove()
      }, 1000)
    },
    onComplete() {
      let body = ':star:'.repeat(this.rating)
      if (this.comment) {
        body += '\n```' + this.comment + '```'
      }
      this.$api.run(sendContact, {
        body,
        type: 'survey',
      })
      this.$survey.confirm()
    },
  },
}
