import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3f755960 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _7fac57ec = () => interopDefault(import('../pages/agb.vue' /* webpackChunkName: "pages/agb" */))
const _765896e5 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _fc9a9f6c = () => interopDefault(import('../pages/checkout_success.vue' /* webpackChunkName: "pages/checkout_success" */))
const _52d2e510 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _af2396da = () => interopDefault(import('../pages/datenschutz.vue' /* webpackChunkName: "pages/datenschutz" */))
const _f236127c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5eaaa175 = () => interopDefault(import('../pages/flows.vue' /* webpackChunkName: "pages/flows" */))
const _754a5032 = () => interopDefault(import('../pages/impressum.vue' /* webpackChunkName: "pages/impressum" */))
const _508c99d9 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2125d2fa = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _1019f88e = () => interopDefault(import('../pages/pricing/index.vue' /* webpackChunkName: "pages/pricing/index" */))
const _11eaaa8c = () => interopDefault(import('../pages/projects.vue' /* webpackChunkName: "pages/projects" */))
const _12a7edc8 = () => interopDefault(import('../pages/redirect.vue' /* webpackChunkName: "pages/redirect" */))
const _4c62d0a8 = () => interopDefault(import('../pages/sources.vue' /* webpackChunkName: "pages/sources" */))
const _fe67adaa = () => interopDefault(import('../pages/try.vue' /* webpackChunkName: "pages/try" */))
const _0a286874 = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _65c52e73 = () => interopDefault(import('../pages/feature/_feature/index.vue' /* webpackChunkName: "pages/feature/_feature/index" */))
const _36cf4aa2 = () => interopDefault(import('../pages/flow/_flow/index.vue' /* webpackChunkName: "pages/flow/_flow/index" */))
const _4d712bcf = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _3f755960,
    name: "account___en"
  }, {
    path: "/agb",
    component: _7fac57ec,
    name: "agb___en"
  }, {
    path: "/blog",
    component: _765896e5,
    name: "blog___en"
  }, {
    path: "/checkout_success",
    component: _fc9a9f6c,
    name: "checkout_success___en"
  }, {
    path: "/contact",
    component: _52d2e510,
    name: "contact___en"
  }, {
    path: "/datenschutz",
    component: _af2396da,
    name: "datenschutz___en"
  }, {
    path: "/de",
    component: _f236127c,
    name: "index___de"
  }, {
    path: "/es",
    component: _f236127c,
    name: "index___es"
  }, {
    path: "/flows",
    component: _5eaaa175,
    name: "flows___en"
  }, {
    path: "/fr",
    component: _f236127c,
    name: "index___fr"
  }, {
    path: "/impressum",
    component: _754a5032,
    name: "impressum___en"
  }, {
    path: "/it",
    component: _f236127c,
    name: "index___it"
  }, {
    path: "/login",
    component: _508c99d9,
    name: "login___en"
  }, {
    path: "/maintenance",
    component: _2125d2fa,
    name: "maintenance___en"
  }, {
    path: "/nl",
    component: _f236127c,
    name: "index___nl"
  }, {
    path: "/pricing",
    component: _1019f88e,
    name: "pricing___en"
  }, {
    path: "/projects",
    component: _11eaaa8c,
    name: "projects___en"
  }, {
    path: "/redirect",
    component: _12a7edc8,
    name: "redirect___en"
  }, {
    path: "/sources",
    component: _4c62d0a8,
    name: "sources___en"
  }, {
    path: "/try",
    component: _fe67adaa,
    name: "try___en"
  }, {
    path: "/de/account",
    component: _3f755960,
    name: "account___de"
  }, {
    path: "/de/agb",
    component: _7fac57ec,
    name: "agb___de"
  }, {
    path: "/de/blog",
    component: _765896e5,
    name: "blog___de"
  }, {
    path: "/de/checkout_success",
    component: _fc9a9f6c,
    name: "checkout_success___de"
  }, {
    path: "/de/contact",
    component: _52d2e510,
    name: "contact___de"
  }, {
    path: "/de/datenschutz",
    component: _af2396da,
    name: "datenschutz___de"
  }, {
    path: "/de/flows",
    component: _5eaaa175,
    name: "flows___de"
  }, {
    path: "/de/impressum",
    component: _754a5032,
    name: "impressum___de"
  }, {
    path: "/de/login",
    component: _508c99d9,
    name: "login___de"
  }, {
    path: "/de/maintenance",
    component: _2125d2fa,
    name: "maintenance___de"
  }, {
    path: "/de/pricing",
    component: _1019f88e,
    name: "pricing___de"
  }, {
    path: "/de/projects",
    component: _11eaaa8c,
    name: "projects___de"
  }, {
    path: "/de/redirect",
    component: _12a7edc8,
    name: "redirect___de"
  }, {
    path: "/de/sources",
    component: _4c62d0a8,
    name: "sources___de"
  }, {
    path: "/de/try",
    component: _fe67adaa,
    name: "try___de"
  }, {
    path: "/es/account",
    component: _3f755960,
    name: "account___es"
  }, {
    path: "/es/agb",
    component: _7fac57ec,
    name: "agb___es"
  }, {
    path: "/es/blog",
    component: _765896e5,
    name: "blog___es"
  }, {
    path: "/es/checkout_success",
    component: _fc9a9f6c,
    name: "checkout_success___es"
  }, {
    path: "/es/contact",
    component: _52d2e510,
    name: "contact___es"
  }, {
    path: "/es/datenschutz",
    component: _af2396da,
    name: "datenschutz___es"
  }, {
    path: "/es/flows",
    component: _5eaaa175,
    name: "flows___es"
  }, {
    path: "/es/impressum",
    component: _754a5032,
    name: "impressum___es"
  }, {
    path: "/es/login",
    component: _508c99d9,
    name: "login___es"
  }, {
    path: "/es/maintenance",
    component: _2125d2fa,
    name: "maintenance___es"
  }, {
    path: "/es/pricing",
    component: _1019f88e,
    name: "pricing___es"
  }, {
    path: "/es/projects",
    component: _11eaaa8c,
    name: "projects___es"
  }, {
    path: "/es/redirect",
    component: _12a7edc8,
    name: "redirect___es"
  }, {
    path: "/es/sources",
    component: _4c62d0a8,
    name: "sources___es"
  }, {
    path: "/es/try",
    component: _fe67adaa,
    name: "try___es"
  }, {
    path: "/fr/account",
    component: _3f755960,
    name: "account___fr"
  }, {
    path: "/fr/agb",
    component: _7fac57ec,
    name: "agb___fr"
  }, {
    path: "/fr/blog",
    component: _765896e5,
    name: "blog___fr"
  }, {
    path: "/fr/checkout_success",
    component: _fc9a9f6c,
    name: "checkout_success___fr"
  }, {
    path: "/fr/contact",
    component: _52d2e510,
    name: "contact___fr"
  }, {
    path: "/fr/datenschutz",
    component: _af2396da,
    name: "datenschutz___fr"
  }, {
    path: "/fr/flows",
    component: _5eaaa175,
    name: "flows___fr"
  }, {
    path: "/fr/impressum",
    component: _754a5032,
    name: "impressum___fr"
  }, {
    path: "/fr/login",
    component: _508c99d9,
    name: "login___fr"
  }, {
    path: "/fr/maintenance",
    component: _2125d2fa,
    name: "maintenance___fr"
  }, {
    path: "/fr/pricing",
    component: _1019f88e,
    name: "pricing___fr"
  }, {
    path: "/fr/projects",
    component: _11eaaa8c,
    name: "projects___fr"
  }, {
    path: "/fr/redirect",
    component: _12a7edc8,
    name: "redirect___fr"
  }, {
    path: "/fr/sources",
    component: _4c62d0a8,
    name: "sources___fr"
  }, {
    path: "/fr/try",
    component: _fe67adaa,
    name: "try___fr"
  }, {
    path: "/it/account",
    component: _3f755960,
    name: "account___it"
  }, {
    path: "/it/agb",
    component: _7fac57ec,
    name: "agb___it"
  }, {
    path: "/it/blog",
    component: _765896e5,
    name: "blog___it"
  }, {
    path: "/it/checkout_success",
    component: _fc9a9f6c,
    name: "checkout_success___it"
  }, {
    path: "/it/contact",
    component: _52d2e510,
    name: "contact___it"
  }, {
    path: "/it/datenschutz",
    component: _af2396da,
    name: "datenschutz___it"
  }, {
    path: "/it/flows",
    component: _5eaaa175,
    name: "flows___it"
  }, {
    path: "/it/impressum",
    component: _754a5032,
    name: "impressum___it"
  }, {
    path: "/it/login",
    component: _508c99d9,
    name: "login___it"
  }, {
    path: "/it/maintenance",
    component: _2125d2fa,
    name: "maintenance___it"
  }, {
    path: "/it/pricing",
    component: _1019f88e,
    name: "pricing___it"
  }, {
    path: "/it/projects",
    component: _11eaaa8c,
    name: "projects___it"
  }, {
    path: "/it/redirect",
    component: _12a7edc8,
    name: "redirect___it"
  }, {
    path: "/it/sources",
    component: _4c62d0a8,
    name: "sources___it"
  }, {
    path: "/it/try",
    component: _fe67adaa,
    name: "try___it"
  }, {
    path: "/nl/account",
    component: _3f755960,
    name: "account___nl"
  }, {
    path: "/nl/agb",
    component: _7fac57ec,
    name: "agb___nl"
  }, {
    path: "/nl/blog",
    component: _765896e5,
    name: "blog___nl"
  }, {
    path: "/nl/checkout_success",
    component: _fc9a9f6c,
    name: "checkout_success___nl"
  }, {
    path: "/nl/contact",
    component: _52d2e510,
    name: "contact___nl"
  }, {
    path: "/nl/datenschutz",
    component: _af2396da,
    name: "datenschutz___nl"
  }, {
    path: "/nl/flows",
    component: _5eaaa175,
    name: "flows___nl"
  }, {
    path: "/nl/impressum",
    component: _754a5032,
    name: "impressum___nl"
  }, {
    path: "/nl/login",
    component: _508c99d9,
    name: "login___nl"
  }, {
    path: "/nl/maintenance",
    component: _2125d2fa,
    name: "maintenance___nl"
  }, {
    path: "/nl/pricing",
    component: _1019f88e,
    name: "pricing___nl"
  }, {
    path: "/nl/projects",
    component: _11eaaa8c,
    name: "projects___nl"
  }, {
    path: "/nl/redirect",
    component: _12a7edc8,
    name: "redirect___nl"
  }, {
    path: "/nl/sources",
    component: _4c62d0a8,
    name: "sources___nl"
  }, {
    path: "/nl/try",
    component: _fe67adaa,
    name: "try___nl"
  }, {
    path: "/",
    component: _f236127c,
    name: "index___en"
  }, {
    path: "/de/blog/:article",
    component: _0a286874,
    name: "blog-article___de"
  }, {
    path: "/de/feature/:feature",
    component: _65c52e73,
    name: "feature-feature___de"
  }, {
    path: "/de/flow/:flow",
    component: _36cf4aa2,
    name: "flow-flow___de"
  }, {
    path: "/es/blog/:article",
    component: _0a286874,
    name: "blog-article___es"
  }, {
    path: "/es/feature/:feature",
    component: _65c52e73,
    name: "feature-feature___es"
  }, {
    path: "/es/flow/:flow",
    component: _36cf4aa2,
    name: "flow-flow___es"
  }, {
    path: "/fr/blog/:article",
    component: _0a286874,
    name: "blog-article___fr"
  }, {
    path: "/fr/feature/:feature",
    component: _65c52e73,
    name: "feature-feature___fr"
  }, {
    path: "/fr/flow/:flow",
    component: _36cf4aa2,
    name: "flow-flow___fr"
  }, {
    path: "/it/blog/:article",
    component: _0a286874,
    name: "blog-article___it"
  }, {
    path: "/it/feature/:feature",
    component: _65c52e73,
    name: "feature-feature___it"
  }, {
    path: "/it/flow/:flow",
    component: _36cf4aa2,
    name: "flow-flow___it"
  }, {
    path: "/nl/blog/:article",
    component: _0a286874,
    name: "blog-article___nl"
  }, {
    path: "/nl/feature/:feature",
    component: _65c52e73,
    name: "feature-feature___nl"
  }, {
    path: "/nl/flow/:flow",
    component: _36cf4aa2,
    name: "flow-flow___nl"
  }, {
    path: "/blog/:article",
    component: _0a286874,
    name: "blog-article___en"
  }, {
    path: "/feature/:feature",
    component: _65c52e73,
    name: "feature-feature___en"
  }, {
    path: "/flow/:flow",
    component: _36cf4aa2,
    name: "flow-flow___en"
  }, {
    path: "/nl/*",
    component: _4d712bcf,
    name: "all___nl"
  }, {
    path: "/it/*",
    component: _4d712bcf,
    name: "all___it"
  }, {
    path: "/es/*",
    component: _4d712bcf,
    name: "all___es"
  }, {
    path: "/fr/*",
    component: _4d712bcf,
    name: "all___fr"
  }, {
    path: "/de/*",
    component: _4d712bcf,
    name: "all___de"
  }, {
    path: "/*",
    component: _4d712bcf,
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
