export default function ({app}) {

  if (!app.mixins) app.mixins = []
  app.mixins.push({
    watch: {
      '$consent.cookies.analytics': {
        handler(newVal) {
          if (newVal) {
            init();
          }
        },
        immediate: true
      }
    },
  })

  function init() {
    if (!process.client) {
      return;
    }
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.tolt.io/tolt.js';
    script.dataset.tolt = '099a0dfa-7ca7-46cf-8df5-e9dc1f39afb5';
    document.head.appendChild(script);
  }

}
