export function getDocuments() {
  return this.get(`/api/documents`)
}

export function getDocumentMessages(uuid) {
  return this.get(`/api/documents/${uuid}/messages`)
}

export function getDocument(uuid) {
  return this.get(`/api/documents/${uuid}`)
}

export function uploadDocuments(data) {
  return this.post(`/api/documents`, data)
}

export function deleteDocument(uuid) {
  return this.del(`/api/documents/${uuid}`)
}
