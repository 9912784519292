export default async function ({ app, route, $cookies, store, $sentry }) {
    try {
      if ('category' in route.query) {
        $cookies.set('onboarding-category', route.query.category)
        $cookies.set('onboarding', true)
      }
      if (!app.$auth.loggedIn) return
      
        const futures = [
            store.dispatch('getDocuments'),
            store.dispatch('getArticles'),
            store.dispatch('getPapers'),
        ]
        await Promise.all(futures)

    } catch (err) {
      $sentry.captureException(err)
    }
  }
  