export function getPapers() {
    return this.get(`/api/papers`)
  }
  
export function bookmarkPaper(uuid) {
    return this.post(`/api/papers/${uuid}/bookmark`)
}
export function unbookmarkPaper(uuid) {
    return this.post(`/api/papers/${uuid}/unbookmark`)
}
  