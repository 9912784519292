export const Fullpage = () => import('../../components/storyblok/Fullpage.vue' /* webpackChunkName: "components/fullpage" */).then(c => wrapFunctional(c.default || c))
export const Inline = () => import('../../components/storyblok/Inline.vue' /* webpackChunkName: "components/inline" */).then(c => wrapFunctional(c.default || c))
export const Landingpage = () => import('../../components/storyblok/Landingpage.vue' /* webpackChunkName: "components/landingpage" */).then(c => wrapFunctional(c.default || c))
export const Page = () => import('../../components/storyblok/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c))
export const BlogArticle = () => import('../../components/storyblok/blog/Article.vue' /* webpackChunkName: "components/blog-article" */).then(c => wrapFunctional(c.default || c))
export const BlogArticleCard = () => import('../../components/storyblok/blog/ArticleCard.vue' /* webpackChunkName: "components/blog-article-card" */).then(c => wrapFunctional(c.default || c))
export const BlogArticlePreview = () => import('../../components/storyblok/blog/ArticlePreview.vue' /* webpackChunkName: "components/blog-article-preview" */).then(c => wrapFunctional(c.default || c))
export const BlogParagraph = () => import('../../components/storyblok/blog/BlogParagraph.vue' /* webpackChunkName: "components/blog-paragraph" */).then(c => wrapFunctional(c.default || c))
export const BasicBild = () => import('../../components/storyblok/basic/Bild.vue' /* webpackChunkName: "components/basic-bild" */).then(c => wrapFunctional(c.default || c))
export const BasicBtn = () => import('../../components/storyblok/basic/Btn.vue' /* webpackChunkName: "components/basic-btn" */).then(c => wrapFunctional(c.default || c))
export const BasicButton = () => import('../../components/storyblok/basic/Button.vue' /* webpackChunkName: "components/basic-button" */).then(c => wrapFunctional(c.default || c))
export const BasicCard = () => import('../../components/storyblok/basic/Card.vue' /* webpackChunkName: "components/basic-card" */).then(c => wrapFunctional(c.default || c))
export const BasicHeadline = () => import('../../components/storyblok/basic/Headline.vue' /* webpackChunkName: "components/basic-headline" */).then(c => wrapFunctional(c.default || c))
export const BasicIcon = () => import('../../components/storyblok/basic/Icon.vue' /* webpackChunkName: "components/basic-icon" */).then(c => wrapFunctional(c.default || c))
export const BasicLogo = () => import('../../components/storyblok/basic/Logo.vue' /* webpackChunkName: "components/basic-logo" */).then(c => wrapFunctional(c.default || c))
export const BasicParagraph = () => import('../../components/storyblok/basic/Paragraph.vue' /* webpackChunkName: "components/basic-paragraph" */).then(c => wrapFunctional(c.default || c))
export const BasicSubheadline = () => import('../../components/storyblok/basic/Subheadline.vue' /* webpackChunkName: "components/basic-subheadline" */).then(c => wrapFunctional(c.default || c))
export const BasicText = () => import('../../components/storyblok/basic/Text.vue' /* webpackChunkName: "components/basic-text" */).then(c => wrapFunctional(c.default || c))
export const BasicTextblock = () => import('../../components/storyblok/basic/Textblock.vue' /* webpackChunkName: "components/basic-textblock" */).then(c => wrapFunctional(c.default || c))
export const BasicVideo = () => import('../../components/storyblok/basic/Video.vue' /* webpackChunkName: "components/basic-video" */).then(c => wrapFunctional(c.default || c))
export const ElementsCard = () => import('../../components/storyblok/elements/Card.vue' /* webpackChunkName: "components/elements-card" */).then(c => wrapFunctional(c.default || c))
export const ElementsCardSocial = () => import('../../components/storyblok/elements/CardSocial.vue' /* webpackChunkName: "components/elements-card-social" */).then(c => wrapFunctional(c.default || c))
export const ElementsFeatureCard = () => import('../../components/storyblok/elements/FeatureCard.vue' /* webpackChunkName: "components/elements-feature-card" */).then(c => wrapFunctional(c.default || c))
export const ElementsOpenPricing = () => import('../../components/storyblok/elements/OpenPricing.vue' /* webpackChunkName: "components/elements-open-pricing" */).then(c => wrapFunctional(c.default || c))
export const ElementsPromtExamples = () => import('../../components/storyblok/elements/PromtExamples.vue' /* webpackChunkName: "components/elements-promt-examples" */).then(c => wrapFunctional(c.default || c))
export const ElementsSocialCard = () => import('../../components/storyblok/elements/SocialCard.vue' /* webpackChunkName: "components/elements-social-card" */).then(c => wrapFunctional(c.default || c))
export const ElementsStaticInput = () => import('../../components/storyblok/elements/StaticInput.vue' /* webpackChunkName: "components/elements-static-input" */).then(c => wrapFunctional(c.default || c))
export const ElementsStaticOutput = () => import('../../components/storyblok/elements/StaticOutput.vue' /* webpackChunkName: "components/elements-static-output" */).then(c => wrapFunctional(c.default || c))
export const ElementsTabitem = () => import('../../components/storyblok/elements/Tabitem.vue' /* webpackChunkName: "components/elements-tabitem" */).then(c => wrapFunctional(c.default || c))
export const ElementsTabs = () => import('../../components/storyblok/elements/Tabs.vue' /* webpackChunkName: "components/elements-tabs" */).then(c => wrapFunctional(c.default || c))
export const ElementsTooltip = () => import('../../components/storyblok/elements/Tooltip.vue' /* webpackChunkName: "components/elements-tooltip" */).then(c => wrapFunctional(c.default || c))
export const LayoutCenter = () => import('../../components/storyblok/layout/Center.vue' /* webpackChunkName: "components/layout-center" */).then(c => wrapFunctional(c.default || c))
export const LayoutColumn = () => import('../../components/storyblok/layout/Column.vue' /* webpackChunkName: "components/layout-column" */).then(c => wrapFunctional(c.default || c))
export const LayoutEqualGrid = () => import('../../components/storyblok/layout/EqualGrid.vue' /* webpackChunkName: "components/layout-equal-grid" */).then(c => wrapFunctional(c.default || c))
export const LayoutFlex = () => import('../../components/storyblok/layout/Flex.vue' /* webpackChunkName: "components/layout-flex" */).then(c => wrapFunctional(c.default || c))
export const LayoutFlexiGrid = () => import('../../components/storyblok/layout/FlexiGrid.vue' /* webpackChunkName: "components/layout-flexi-grid" */).then(c => wrapFunctional(c.default || c))
export const LayoutFullwidth = () => import('../../components/storyblok/layout/Fullwidth.vue' /* webpackChunkName: "components/layout-fullwidth" */).then(c => wrapFunctional(c.default || c))
export const LayoutRow = () => import('../../components/storyblok/layout/Row.vue' /* webpackChunkName: "components/layout-row" */).then(c => wrapFunctional(c.default || c))
export const LayoutSpace = () => import('../../components/storyblok/layout/Space.vue' /* webpackChunkName: "components/layout-space" */).then(c => wrapFunctional(c.default || c))
export const LayoutSpacer = () => import('../../components/storyblok/layout/Spacer.vue' /* webpackChunkName: "components/layout-spacer" */).then(c => wrapFunctional(c.default || c))
export const LayoutThemecontainer = () => import('../../components/storyblok/layout/Themecontainer.vue' /* webpackChunkName: "components/layout-themecontainer" */).then(c => wrapFunctional(c.default || c))
export const PricingFaq = () => import('../../components/storyblok/pricing/Faq.vue' /* webpackChunkName: "components/pricing-faq" */).then(c => wrapFunctional(c.default || c))
export const SectionsExamples = () => import('../../components/storyblok/sections/Examples.vue' /* webpackChunkName: "components/sections-examples" */).then(c => wrapFunctional(c.default || c))
export const SectionsHero = () => import('../../components/storyblok/sections/Hero.vue' /* webpackChunkName: "components/sections-hero" */).then(c => wrapFunctional(c.default || c))
export const SectionsStaticDemo = () => import('../../components/storyblok/sections/StaticDemo.vue' /* webpackChunkName: "components/sections-static-demo" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
