export default async function ({ app, route, $cookies, store, $sentry }) {
  try {
    if ('category' in route.query) {
      $cookies.set('onboarding-category', route.query.category)
      $cookies.set('onboarding', true)
    }
    if (!app.$auth.loggedIn) return

    const { templates } = await store.dispatch('getTemplates')
    const category = $cookies.get('onboarding-category')
    if (!category) return

    $cookies.remove('onboarding-category')

    const templatesInCategory = templates.filter((template) =>
      template.categories?.includes(category)
    )
    templatesInCategory.forEach((templateInCategory) => {
      store.dispatch('toggleFavourite', {
        template: templateInCategory,
        value: true,
      })
    })
  } catch (err) {
    $sentry.captureException(err)
  }
}
