export default async function ({ app, route, $cookies, store, $sentry }) {
    try {
      if ('category' in route.query) {
        $cookies.set('onboarding-category', route.query.category)
        $cookies.set('onboarding', true)
      }
      if (!app.$auth.loggedIn) return
  
      await store.dispatch('getGroups')
    } catch (err) {
      $sentry.captureException(err)
    }
}
  