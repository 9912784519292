export function getCustomer() {
  return this.get(`/api/auth/user/customer`)
}

export function setReferral(referrer) {
  return this.post(`/api/auth/user/set_referrer`, { referrer })
}

export function deleteUser() {
  return this.del(`/api/auth/user`)
}